import React from "react";

const Videos = () => {
  return (
    <section
      id="video"
      className="video uk-section"
      data-uk-scrollspy="cls:uk-animation-fade; offset-top: 80px; delay: 300"
    >
      <div className="container--min uk-container uk-margin-large-bottom">
        <h2 className="uk-h2 uk-margin-bottom">Videos</h2>
        <iframe
          width="315"
          height="560"
          src="https://www.youtube.com/embed/videoseries?si=owBN-ArvNjk5yeir&amp;list=PLgEV1nI2E4K1W_mMRXwJjaQ3OOA5DgVWN"
          title="YouTube video player"

          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullscreen
        ></iframe>{" "}
      </div>
    </section>
  );
};

export default Videos;
